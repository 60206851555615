import React, { useState, useEffect,Fragment } from "react";
import Home from "./pages/Home";
// import { getTicketIdUrl } from "./serviceUrls/google_sheetUrl";
import axios from "axios";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-loader-spinner";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Confirm from "./components/Confirm";

import "./App.scss";
import SuccessAlert from "./components/SuccessAlert";
// import ErrorAlert from "./components/ErrorAlert"
const App = () => {
  // let deviceapi = "https://central-device-apis.el.r.appspot.com";
  let device1api = "https://device1apigcp.el.r.appspot.com";
  let device2api = "https://device2apigcp.el.r.appspot.com";
  let device3api = "https://device3apigcpupdated.el.r.appspot.com";
  let device4api = "https://device4apigcp.el.r.appspot.com";
  let device5api = "https://device5apigcp.el.r.appspot.com";
  let device6api = "https://device6apigcp.el.r.appspot.com";
  let device7api = "https://device7apigcp.el.r.appspot.com";
  let device8api = "https://device8apigcp.el.r.appspot.com";
  let device9api = "https://device9apigcp.el.r.appspot.com";
  let device10api = "https://device10apigcp.el.r.appspot.com";
  let device11api = "https://device11apigcp.el.r.appspot.com";
  let device12api = "https://device12apigcp.el.r.appspot.com";
  let device13api = "https://device13apigcp.el.r.appspot.com";
  let device14api = "https://device14apigcp.el.r.appspot.com";
  let device15api = "https://device15apigcp.el.r.appspot.com";
  let device17api = "https://device17apigcp.el.r.appspot.com";
  let device18api = "https://device18apigcp.de.r.appspot.com";
  let device19api = "https://device19apigcp.el.r.appspot.com";
  let device20api = "https://device20apigcp.el.r.appspot.com";
  let device21api = "https://device21apigcp.el.r.appspot.com";
  let device22api = "https://device22apigcp.el.r.appspot.com";
  
  const [isConfirm, setIsConfirm] = useState(false);
  const [haveInterest, setHaveInterest] = useState(false);
  

const showConfirm=()=>{
  setIsConfirm(true)
};
const hideConfirm=()=>{
  setIsConfirm(false)
};


  // const filteredData = authData?.filter(
  //   (i) => i.whatsapp == uniqueId && i.ticketId == ticketNo
  // );
  // console.log(authData);

  return (
    <Fragment>
      
    <BrowserRouter>
      <div className="App">
        <Switch>
        <Route exact path="/d1/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device1api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device1api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d2/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device2api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device2api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d3/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device3api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device3api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d4/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device4api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device4api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d5/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device5api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device5api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d6/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device6api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device6api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d7/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device7api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device7api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d8/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device8api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device8api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d9/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device9api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device9api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d10/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device10api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device10api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d11/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device11api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device11api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d12/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device12api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device12api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d13/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device13api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device13api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d14/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device14api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device14api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d15/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device15api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device15api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d17/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device17api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device17api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d18/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device18api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device18api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d19/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device19api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device19api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d20/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device20api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device20api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d21/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device21api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device21api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/d22/tutorForm/:ssnId/:tutId">
        {isConfirm && <Confirm _api = {device22api+"/v1/api/orders"} hideConfirm={hideConfirm} haveInterest={haveInterest}/>}
          <Home
            _api = {device22api+"/v1/api/orders"}
            showConfirm={showConfirm}
            hideConfirm={hideConfirm}
            setHaveInterest={setHaveInterest}
          />
        </Route>
        <Route exact path="/success_TutorForm">
         <SuccessAlert />
        </Route>
        <Route exact path="/shown_interest">
          <div><h1>You have already shown your interest</h1></div>
        </Route>
        </Switch>
      </div>
    </BrowserRouter>
    </Fragment>
  );
};

export default App;
