import React from 'react';
import classes from './style.module.css';
import logo from '../assets/ok.gif'

const SuccessAlert = () => {
  window.history.pushState(null, "", window.location.href);
window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
}
  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div
          className={classes['appointment-form']}
        >
          <img
            src={logo}
            alt='loading...'
            className={classes.oklogo}
          />
          <h2>Thank You</h2>
          <h2 className={classes['success_text']}>Your response is successfully submitted.</h2>
        </div>
      </div>
    </div>
  );
};

export default SuccessAlert;
