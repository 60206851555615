import React from 'react';

const ErrorAlert = ({errorMsg}) => {
  

  return (
    <div style={{textAlign:"center", marginTop: '20%'}}>
      <h1>{errorMsg}</h1>
      {/* <h3>You have already shown your Interest</h3> */}
    </div>
  );
};

export default ErrorAlert;
