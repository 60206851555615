import React, { useState } from 'react';
import classes from './confirm.module.css'
import Modal from './Modal'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { useHistory, useParams, useLocation } from "react-router-dom";

const Confirm = (props) => {


    const {ssnId,tutId} = useParams();

    const tutor_Interested=async()=>{
        const resultTutor= await axios.post( `${props._api}/storeTutorResponse`,
        {
          sessionId: ssnId,  tutorId:tutId
        })

        if(resultTutor.data){
            console.log({sendTutor:"success"})
            props.hideConfirm()
            
        }
        else{
            console.log({sendTutor:"failed"})
        }
    }

    
    return (
        <Modal>
            <div>
                <p><b>Are you sure?</b></p>
            </div>
            <div className={classes.actions}>
                {!props.haveInterest&&<Link to="/success_TutorForm"><button onClick={tutor_Interested} className={classes.button}>Yes</button></Link>}
                {props.haveInterest&&<Link to="/success_TutorForm"><button  className={classes.button}>Yes</button></Link>}
                <button onClick={props.hideConfirm} className={classes['button--alt']}>No</button>
            </div>
            </Modal>
    );
};

export default Confirm;